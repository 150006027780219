import { ISettingColour, IDashboardTarget } from "@/models/Dashboard";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const DASHBOARD = "Dashboard";
const env = JSON.parse(localStorage.getItem("env") as string);
export class DashboardAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getCarbonEmissionsSummary = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${DASHBOARD}/carbon-emissions-summary`)
      .catch(catchAxiosError);
    return response;
  };

  public getScopeTwoEmissions = async (
    params: string | number | Date
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/ScopeTwoEmissions/${params}`)
      .catch(catchAxiosError);

    return response;
  };

  public createNewTarget = async (
    data: IDashboardTarget
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`/ScopeTwoEmissions/${data.Year}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public createDataSustainabilityAchievements = async (
    data: any
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${DASHBOARD}/sustainability-achievements`, data)
      .catch(catchAxiosError);

    return response;
  };

  public filterColourSetting = async (
    params: ISettingColour
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${DASHBOARD}/get-colour-setting`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
