
import { defineComponent } from "vue";
import isEmpty from "lodash/isEmpty";
import { Form, Field } from "vee-validate";

import DialogModule from "@/store/UI/Dialog";

export default defineComponent({
  components: {
    Form,
    Field,
  },
  data() {
    return {
      isToggle: DialogModule.isToggle,
      isEdit: !isEmpty(DialogModule.getData),
    };
  },
  computed: {
    initialValues() {
      return (
        DialogModule.getData || {
          Year: new Date(),
        }
      );
    },
  },
  methods: {
    handleClose() {
      DialogModule.hideModal();
    },
    async onSubmit(values: any) {
      const { rest } = DialogModule.getRest as any;
      rest(values);
      DialogModule.hideModal();
    },
  },
});
